#left-sidebarNav {
    z-index: 1000;
    position: fixed;
    width: 220px;
    height: 100%;
    left: 0px;
    top: 0px;
    background: var(--gray-200);
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    text-align: left;
    display: flex;
    flex-direction: column;
}

/*==== NAV TOGGLE BUTTON ==== */
button#nav-toogle {
    background-color: var(--gray-100);
    width: 43px;
    height: 41px;
    font-size: 20px;
    color: var(--gray-800);
    position: absolute;
    left: calc(100% + 21px);
    top: 11px;
    border-radius: 5px;
    padding: 9px;
}

button#nav-toogle:hover {
    background-color: var(--gray-200);
}

button#nav-toogle svg {
    overflow: hidden;
    vertical-align: middle;
}

.closeNav button#nav-toogle svg {
    margin: 7px;
}

.menu-toggle span {
    background-color: currentColor;
    height: 2px;
    transition-duration: .15s;
    transition-duration: .25s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    width: 1.25rem;
    display: block;
    margin: 5px 0 4px 3px;
}

.menu-toggle span:nth-child(2) {
    width: .75rem;
}

/*
aside:not(.closeNav) .menu-toggle span:nth-child(2) {
    display: none;
}

aside:not(.closeNav) .menu-toggle span:first-child {
    transform: rotate(-35deg);
    width: 14px;
}

aside:not(.closeNav) .menu-toggle span:nth-child(3) {
    transform: rotate(35deg);
    width: 14px;
} */

.closeNav button#nav-toogle i {
    transform: rotate(180deg);
}

button#nav-toogle i {
    float: right;
    margin-right: 20px;
}

.closeNav button#nav-toogle i {
    float: initial;
    margin-right: 0px;
}

.closeNav .aside-footer .version {
    display: none;
}

/*== Logo ==*/
#left-sidebarNav.closeNav {
    width: 55px;
}

.breadcrumb-item.active {
    color: var(--white);
}

#left-sidebarNav.closeNav nav .sidebar-header .sidebar-logo {
    padding: 11px 0px 13px;
}

#left-sidebarNav nav .sidebar-header .sidebar-logo {
    padding: 11px 0px 12px;
    text-align: center;
    background-color: var(--gray-900);
}

#left-sidebarNav nav .sidebar-header .sidebar-logo img {
    max-width: 40px;
    margin: 0 auto;
    display: none;
}

#left-sidebarNav:not(.closeNav) nav .sidebar-header .sidebar-logo img.openNav-logo {
    max-width: 92px;
    display: block;
}

#left-sidebarNav.closeNav nav .sidebar-header .sidebar-logo img.closeNav-logo {
    display: block;
    margin: 0 auto;
}

/*==== NAV TEXT ==== */
#left-sidebarNav li {
    padding: 5px 6px;
    width: 100%;
}

#left-sidebarNav li a {
    display: flex;
    align-items: center;
    color: var(--text-color);
    width: 100%;
    line-height: 19px;
    font-size: 14px;
}

#left-sidebarNav .nav-inner .list-unstyled>li>a {
    padding: 7px;
    border-radius: 3px;
}

#left-sidebarNav .nav-inner .list-unstyled>li>a:not(.active):hover {
    background-color: var(--gray-300);
}

#left-sidebarNav li a.btn {
    color: var(--red-200);
}

#left-sidebarNav .list-unstyled>li>a.active {
    background-color: var(--primary-400);
}

#left-sidebarNav .list-unstyled>li>a.active svg {
    fill: var(--white);
}

#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li>a {
    min-width: 210px;
}

#left-sidebarNav.closeNav span.nav-text {
    display: none;
}

/* #left-sidebarNav li a.active span.nav-text {
    color: var(--white);
} */

/* #left-sidebarNav .list-unstyled>li>a.active svg path {
    fill: var(--white);
} */

/*==== DROPDOWN MENU ==== */
#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-menu {
    transform: none !important;
    display: block;
    padding: 0;
    transition: all 0.4s ease;
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}

#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li .dropdown-menu {
    border: 0 none;
    position: static !important;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li .dropdown-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    border-color: var(--gray-400);
    box-shadow: 1px 1px 10px var(--gray-200);
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li:hover .dropdown-menu,
#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li .dropdown-menu.show {
    height: 97px;
    overflow-y: scroll;
    opacity: 1;
    visibility: visible;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li:hover .dropdown-menu.show {
    inset: 0 56px !important;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li.dropdown:hover::before {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--white);
    right: -8px;
    top: 27px;
    transform: rotate(45deg);
    content: '';
    display: block;
    border: 1px solid var(--gray-400);
    border-top: 0 none;
    border-right: 0 none;
    z-index: 24;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-toggle::after {
    margin-left: auto;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li .dropdown-toggle::after {
    display: none;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item {
    color: var(--gray-600);
    border-radius: 4px;
    padding: 8px;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item:hover {
    background-color: var(--gray-200);
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item.active {
    background-color: transparent;
    color: var(--secondary-600);
}

/*==== LEFT SIDEBAR FOOTER ICONS ==== */
.aside-footer {
    position: absolute;
    bottom: 0;
    left: 0px;
    padding: 10px 0px 0px;
    width: 100%;
    border-top: 1px solid var(--gray-300);
}

.aside-footer ul {
    margin-bottom: 1px;
    width: 100%;
    padding: 0 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aside-footer li.dropdown {
    cursor: pointer;
}

.aside-footer li {
    margin: 10px 0px;
    color: var(--black);
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.closeNav .aside-footer li {
    margin: 10px 0px;
}

.closeNav .aside-footer ul {
    text-align: inherit;
}

.aside-footer li a {
    color: var(--black);
    font-size: 15px;
}

/* .aside-footer li a span {
    padding-left: 6px;
}
*/
.aside-footer img {
    width: 22px;
}

/* .cls-1 {
    fill: #868990;
  } */


.svgWidth {
    padding-right: 5px;
    width: 32px;
}

.svgWidth svg {
    fill: rgb(134, 137, 144);
}

#exit svg {
    fill: transparent;
}

.aside-footer .profile_svg {
    width: 30px;
}

.logout .profile_svg {
    margin-left: 4px;

}
@media (max-width:1367px) {
    #left-sidebarNav .nav-inner .list-unstyled>li>a {
        padding: 4px 7px;
    }
    #left-sidebarNav li {
        padding: 3px 6px;
      }
}

@media (max-width:1360px) {
    #left-sidebarNav li {
        padding: 3px 6px;
    }
}