.showHidePass.modalBox {
    background-color: var(--white);
    right: 30px;
    bottom: 25px;
}
.relayError + .showHidePass.modalBox {
    right: 38px;
} 
.switchBox .form-switch {
	padding-left: 2.5em;
}
.switchBox .form-switch .form-check-input {
    width: 32px;
    height: 18px;
    margin-top: 1px;
}