.progress.step-progress {
	position: relative;
    height: 3px;
}
.step-container {
	padding: 10px 110px 40px 0px;
}
.step-detail {
	padding: 0 4px;
	background: var(--white);
	font-size: 12px;
	color: var(--gray-500);
	position: absolute;
	left: 100%;
	top: -1px;
	width: 115px;
    text-align: left;
}
.step-circle {
	background: var(--gray-300);
	color: var(--white-color);
	width: 25px;
	text-align: center;
	height: 25px;
	line-height: 25px;
	border-radius: 50%;
	margin-top: -25px;
	position: relative;
	z-index: 3;
}
.step-circle.active {
	background: var(--secondary-600);
}
.step-circle.completed {
	background: var(--green-0);
    color: var(--green-200);
}
