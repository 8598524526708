.tableDevicesPanel {
  height: calc(52vh - 200px);
  border: 1px solid #eee;
  padding: 0px;
}
.tableDevicesPanel.table-status {
  height: auto;
}
td.btn-light-danger.parking-status {
  padding: 0px;
  width: 100px;
}

.badge-parking {
  padding: 17px 10px;
  width: 100px;
}

.badge.badge-parking {
  border-radius: 0;
}

.badge.badge-parking.badge-light-success {
  color: rgb(10, 155, 65);
  background: rgb(214, 246, 230);

}

.badge.badge-parking.badge-light-secondary {
  background: rgb(252, 242, 199);
  color: rgb(243, 152, 0);

}

.group_detailsWrapper .badge-parking {
  padding: 4px 10px;
  border-radius: 2px;
}

.badge.badge-parking.badge-light-danger {
  background: rgb(251, 224, 230);
  color: rgb(239, 40, 65);

}

.badge.badge-parking.badge-light-dark {
  background: rgb(224, 225, 225);
  color: rgb(102, 100, 100);

}
.open-close {
  width: 84px;
}
.open-close .btn-light-primary {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}