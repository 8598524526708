.tableDesign .table p.wrap_20 {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableDesign .table thead th.wrap_10 {
    max-width: 115px;
    white-space: break-spaces;
    word-break: break-word;
}

.tableDesign .table thead th[class*="route_column"] {
    white-space: break-spaces;
    word-break: break-word;
}

.route_column_1{
    width: 220px;
}

.route_column_2 {
    width: 290px;
}

.route_column_4, .route_column_3 {
    width: 86px;
}

.route_column_5 {
    width: 180px;
}