.page-number input {
    padding: 0 3px;
    height: 23px;
    color: #030303;
    margin: 0px;
    width: auto;
    background: transparent;
}

.tableUser th:nth-child(3n),
.tableUser th:nth-child(4n),
.tableUser th:nth-child(5n) {
    width: 60px;
}

.tableUser td:nth-child(3n),
.tableUser td:nth-child(4n),
.tableUser td:nth-child(5n) {
    text-align: center;
    border-left: 0px;
    border-right: 0px;
    width: 60px;
}

.tableUser thead th:last-child {
    width: 60px;
}
.add-user-modal.correction-modal .showHidePass.modalBox {
    top: 11px;
  }
@media (max-width: 1135px) {
    .tableDesign.row-eq-height.tableUser {
        margin-top: 33px;
    }
}
@media (max-width: 768px) {
    .add-list {
        margin: 0;
        float: left !important;
    }
    .tableDesign.row-eq-height.tableUser {
        margin-top: 30px;
    }
}
@media (max-width: 500px) {
    .tableDesign.row-eq-height.tableUser {
        margin-top: 31px;
        height: calc(100vh - 119px);
    }
}

@media (max-width: 380px) {
    .tableDesign.row-eq-height.tableUser {
        margin-top: 29px;
    }

}