.showHidePassTenant {
    position: absolute;
    opacity: 0.5;
}

.showHidePassTenant.modalBox {
    background-color: var(--white);
    right: 10px;
    bottom: 10px;
}

.threshold_heading {
    color: #fff;
}

.table.threshold-table td {
  position: relative;
  padding: 35px 8px;
}

.circleGreen {
    width: 30px;
    /* Adjust the size as needed */
    height: 5px;
    /* Same as width to make it a circle */
    border-radius: 0%;
    /* Makes the div a circle */
    background-color: green;
    /* Change color as needed */
    margin-top: 3px;
  }
  
  .circleRed {
    width: 30px;
    /* Adjust the size as needed */
    height: 5px;
    /* Same as width to make it a circle */
    border-radius: 0%;
    /* Makes the div a circle */
    background-color: Red;
    /* Change color as needed */
    margin-top: 3px;
  }
  
  .circleYellow {
    width: 30px;
    /* Adjust the size as needed */
    height: 5px;
    /* Same as width to make it a circle */
    border-radius: 0%;
    /* Makes the div a circle */
    background-color: #F39800;
    /* Change color as needed */
    margin-top: 3px;
  }

  .ft-yellow {
    color: #F39800;
  }

  .ft-green {
    color: green;
  }

  .ft-red {
    color: red;
  }
  