.light1 {
    background-color: #ff8427 !important;
    color: black !important;
    width: 130px;
}

.light2 {
    background-color: #92d050 !important;
    color: black !important;
    width: 130px;
}

.light3 {
    background-color: #ffbd47 !important;
    color: black !important;
    width: 130px;
}

.light4 {
    background-color: #00b0f0 !important;
    color: black !important;
    width: 130px;
}

.link-out {     width: 130px;}


.table.linkbaseout-table td {
    border: 1px solid var(--gray-200);
}
