
/* .bg-img1.background {
    background: #252027;
    background-image: url(../images/auth-one-bg.jpg);
  background-position: center;
  background-position: 50% -257px;

} */

/* .login {
    min-width: 850px;
    background: url(../../assets/Images/login-bg1.jpg);
    height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  overflow: auto;  background-position: 50% -257px;

  } */
/* .background {
    background: url(../../assets/Images/login-bg1.jpg);
  background-position: 43% -262px;
    background-repeat: no-repeat;
} */
/* .bg-img1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

  } */
  .bg-img3.background {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginBox {
    width: 312px;
    margin: 44px auto 0;
  }
  .form-login {
    position: relative;
  }
.forget label {
    color: var(--white);
    font-size: 14px;
}
.svg-bg img {
  width: 1200px;
}
.background-fixed {
    position: fixed;
    top: -256px;
    right: 0;
    left: -378px;
  }
.forget input.form-control {
    border-color: var(--white);
    background-color: var(--white) !important;
    border-radius: 6px;
    padding: 10px 35px 10px 10px;
    margin-top: 4px;
    line-height: 1;
    min-height: 37px;
    
}

/* .login input.form-control:hover,
.login input.form-control:focus,
.login input.form-control:active {
    border-color: var(--primary-500);
    background-color: var(--white);
    box-shadow: 0 0 3px var(--primary-500);
} */

.checkbox .cr {
    background: transparent;
    border: 1px solid var(--white);;
}

.showHidePass {
    position: absolute;
    top: 37px;
    right: 10px;
    opacity: 0.5;
}
.user_login {
    position: absolute;
    top: 35px;
    right: 10px;
    opacity: 0.5;
}
.showHideOuterDiv {
    position: relative
}

.loginBox .checkbox {
    display: flex;
    width: 100%;
}

.checkbox-with-label {
    width: 100%;
    margin-bottom: 10px
}

 .forget button.btn.btn-primary {
   background: var(--primary-600); 
     font-size: 13px; 
    border-color: var(--primary-600);
    border-radius: 0.45rem !important;
    /* margin: 20px 0 0; */
    height: auto;
    /* font-weight: bold; */
    /* letter-spacing: 1px; */
}

.font-white {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.back_login {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-500);
}

.forget .btn {
   background-color: var(--secondary-500);
    color: var(--white);
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 40px
} 

.forget .form-group {
    margin-bottom: 0.9rem;
}

.remember {
    color: var(--white);
    font-weight: normal;
}

label.withlabel-checkbox1 {
    width: auto;
    margin-top: 5px;
}

.forgetPas {
    cursor: pointer;
    color: black;
    margin-top: 10px;
}

.logoLogin {
  width: 191px;
  margin: 0 auto;
  position: relative;
  top: 82px;
}
.login-wave {
  width: 84%;
  height: auto;
  margin: 0 auto;
  display: block;
}
img.LightSmallLogo {
    display: none;
}


/* Create a custom checkbox */
.login .with-label-checkbox .checkmark {
    background-color: transparent;
  border: 1px solid var(--white);
}
/* On mouse-over, add a grey background color */
/* .login .with-label-checkbox:hover input~.checkmark {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
} */

/* When the checkbox is checked, add a blue background */
.login .with-label-checkbox input:checked~.checkmark {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
}

.showHidePass.modalBox {
  background-color: var(--white);
  right: 20px;
  top: 38px;
}

.logoMonoz {
    position: fixed;
    padding-left: 20px;
    bottom: 0;
    right: 39px;
  }
  .powered {
    position: absolute;
    top: -15px;
    font-size: 11px;
    right: 0;
  }
  .logoMonoz img {
    max-width: 150px;
  }

  .resetPasswordBox {
    width: 370px;
    margin: 44px auto 0;
}
  @media (max-width: 767px) { 
  .logoLogin {
    width: 55%;
    padding: 10px;
    margin: 0 auto;
  }
  .bg-img3.background {
    min-height: auto;
  }
  .login-wave {
    width: 100%;
  }
}
  @media (max-width: 575.98px) { 
    .loginBox {
        width: 80%;
        top: 0;
      }
      .forget {
        background-size: cover;
      }
     }


