.signBoard.message {
    height: 200px;
  }
  .signboard_images img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
  }
  .image_box.signboard_images {
    height: inherit;
  }