.redColor{
    font-weight: 600;
    color: Red !important;
}

.greenColor {
    font-weight: 600;
    color: green !important;
}

.route-modal .modal-dialog {
    max-width: 1200px;
}

.tableIndex{
    z-index: 0;
}
.edit_checkbox {
    width: 25px;
    height: 25px;
}

.edit_checkbox input + .checkmark {
    border: 0 none !important;
    width: 25px;
    height: 25px;;
}
.edit_checkbox input:checked + .checkmark {    
    background: url(../../../assets/Images/remove.svg) no-repeat center / contain;
}
.edit_checkbox input:checked + .checkmark:after {
    display: none;
}
.edit_checkbox input:not(:checked) + .checkmark {
    background: url(../../../assets/Images/edit.svg) no-repeat center / contain;
}

.form-group.btn-route .btn.btn-primary {
    width: 100%;
    white-space: nowrap;
  }

  .tableDesign .table p.wrap_20 {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableDesign .table thead th.wrap_10 {
    max-width: 115px;
    white-space: break-spaces;
    word-break: break-word;
}

.tableDesign .table thead th[class*="route_col"] {
    white-space: break-spaces;
    word-break: break-word;
}

.route_col_1{
    width: 220px;
}

.route_col_2 {
    width: 270px;
}

.route_col_4, .route_col_3 {
    width: 65px;
}

.route_col_5 {
    width: 180px;
}