.threshold_heading {
    color: #fff;
}

.parking-threshold .MuiSlider-track::after {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 0;
    background: red;
    width: 400px;
    height: 4px;
}
.parking-threshold .MuiBox-root {
    padding-left: 20px;
    padding-right: 10px;
    overflow: hidden;
    padding-top: 35px;
    margin-top: -35px;
    position: relative;
    z-index: 5;
}

.MuiSlider-mark {
    display: none;
}

.parking-threshold span.MuiSlider-markLabel.MuiSlider-markLabel:nth-last-child(2) {
    left: auto !important;
    right: -10px;
    transform: none !important;
}

.threshold-modal.modal-xxl .MuiBox-root {
    width: 507px !important;
  }
  .threshold-modal.modal-xxl .MuiSlider-markLabel {
    font-size: 14px;
    color: var(--text-color);
  }