.status_image {
  /* justify-content: center; */
  /* align-items: center; */
  width: 55%;
}
.status_image {
  width: 100px;
  height: 100%;
  pointer-events: none;
}
.status-images-block {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border: 1px solid #eee;
}
.status-modal .modal-dialog {
  max-width: 900px;
}

.textAlign{
  text-align: end;
}
.active-row td {
  background: #eee;
}
.modal-message.message {
  height: 200px;
}
.message.text-center.modal-message p {
  font-size: 20px;
  margin-top: 10px;
}
.modal-message.message .no_message {
  width: 72px;
}

.modal-status-from .tableDesign thead {
  position: inherit;
  top: 0;
}
