.showHidePassTenant {
    position: absolute;
    opacity: 0.5;
}

.showHidePassTenant.modalBox {
    background-color: var(--white);
    right: 10px;
    bottom: 10px;
}
.box-img-bg {
  background: #f7f7f7;
  display: block;
  margin: 0 auto;
  text-align: center;
}
.box_image {
  background-color: black;
  width: 50px;
  height: 50px;
  margin: 5px 0;
}
.box-img-bg .ms-4 {
  padding: 10px 0 0 0;
  margin-left: 0px !important;
}
.table.camera-table .text-danger.errorMessage {
    position: absolute;
    bottom: 3px;
    font-size: 11px;
    width:auto;
  }
  .table.camera-table td {
    position: relative;
    padding: 18px 8px;
  }
  .modal-dialog .tableDesign.border {
    padding: 0px;
    overflow: auto;
  }
  .arrow_img {
    margin-right: 10px;
  }
   /* .modal-table-scroll.tableDesign {
    height: 260px;
    overflow-y: auto;
  } */