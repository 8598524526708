.btn-table {
	font-size: 15px !important;
	padding: 3px 10px !important;
	color: var(--white);
}

.buttonIcon {
	color: var(--gray-500);
}

.buttonIcon :hover {
	color: var(--secondary-500);
}

.tileContainer {
	background-color: var(--white);
	width: 100%;
	height: calc(100% - 20px);
	padding: 12px;
}

.tileHeading {
	font-size: 20px;
	font-weight: bolder;
	height: 50%;
	display: block;
	float: left;
	padding: 15px 0 0 5px;
	width: calc(65% - 5px);
}

.tileValue {
	font-size: 16px;
	float: left;
	padding: 8px 0px 0px 6px;
	width: 35%;
	text-align: right;
	word-break: break-all;
	line-height: 22px;
}

.kpi {
	float: left;
	width: 100%;
	font-size: 18px;
	font-weight: normal;
	word-break: break-all;
	line-height: 18px;
}

.tileHeading h3 {
	font-size: 15px;
	color: var(--gray-700);
	text-transform: capitalize;
	word-break: break-all;
}

.table {
	border-collapse: collapse;
	width: 100%;
	height: calc(100% - 20px);
	background-color: var(--white);
	overflow-y: auto;
}

.table td,
.table th {
	border: 0 none;
	text-align: left;
	padding: 7px 10px;
	white-space: nowrap;
	line-height: 1.2;
}
.table th {
	padding-top: 12px;
	padding-bottom: 12px;
	font-weight: 700;
	line-height: 1;
}
.table-sm .table td,.table-sm  .table th {
	padding: 8px;
}

.table tr:nth-child(even) {
	background-color: var(--gray-0);
}
.table tr:nth-child(2n) td {
	background-color: var(--gray-0);
}
.paginate {
	float: right;
}

.paginate li,
.paginate li a {
	height: 30px;
}

.tableHeader {
	margin-top: 10px;
	float: left;
	width: 100%;
}

.pageLimit {
	margin-left: 8px;
	height: 25px;
	border: 1px solid var(--gray-300);
	border-radius: 4px;
	background-color: var(--white);
	color: var(--gray-700);
}

.legendColor {
	width: 5px;
	height: 100%;
	float: left;
}

.paginateArea {
	float: right;
}
.colorWhite {
	color: var(--black);
}

.bg-row {
	background-color: var(--gray-400) !important;
}

.nonSelectable {
	background-color: var(--gray-100);
	cursor: not-allowed;
}

.fs11 {
	font-size: 10px;
}

.bgTag {
	background: var(--gray-400);
	color: var(--gray-500);
	margin: 2px;
	border-radius: 4px;
	font-size: 0.8rem;
	padding: 4px 5px;
	position: relative;
}

.bgTag.primary {
	background: var(--primary-0);
	color: var(--primary-600);
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: max-content;
}
.form-switch .form-check-input:focus,
.form-switch .form-check-input {
	width: 40px;
	height: 20px;
	border-color: var(--gray-500);
	outline: 0;
	background-color: var(--white);
	box-shadow: none;
}
.form-switch .form-check-input:checked:focus,
.form-check-input:focus,
.form-check-input:checked {
	background-color: var(--primary-500);
	box-shadow: none;
	border-color: var(--primary-500);
}
.form-switch .form-check-input:focus {
	--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
	border: 1px solid var(--gray-500) !important;
}
.form-switch .form-check-input:checked:focus {
	--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
	border: 1px solid var(--primary-500) !important;
}

.fs08 {
	font-size: 0.8rem;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary-500);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-500);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 40px;
}

.slider.round:before {
  border-radius: 50%;
}

.buttonIcon.is_active_btn{
	width: 17px;
}
.changingPanel tr.bg-row.SelectedRow:hover,
.bg-row.SelectedRow,
.SelectedRow td {
	color: #262626;
	background-color: #ffe5ca3d !important;
}
