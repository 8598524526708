input[type="radio"]+label {
    position: relative;
    padding: 0 0 0 18px !important;
    line-height: 25px;
}

.user-profile-popup .tab-content {
    margin-bottom: 0px;
}

.nav-tabs {
    border-bottom: 1px solid #404656;
}

.user-details i.fa.fa-user.float-left {
    font-size: 28px;
    margin: 5px 10px 0px 0px;
    border-right: 1px solid #404656;
    padding: 5px 10px 5px;
}

.close:focus,
.close:hover {
    color: #31859b;
}

a#controlled-tab-example-tab-Settings {
    display: none;
}

.lang {
    padding-top: 8px;
}
.profile_modal_icon {
    background-color: var(--gray-600);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
}

.add-profile-modal input[type=radio]:checked+label svg {
    fill: var( --secondary-400);
    background-color: #f3920017;
    border-color: var( --secondary-400);
}
.add-profile-modal label svg {
    fill: var(--gray-500);
    border: 2px solid var(--gray-300);
    border-radius: 6px;
    margin: 16px 0;
    padding: 13px 0;
    width: 100%;
}

input[type=radio] {
    opacity: 0;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    position: absolute;
    width: auto;
}
input[type="radio"]+label::after {
    opacity: 0;
    display: none;
}
input[type="radio"]+label::before {
    display: none;
}
.user-profile-popup .showHidePass.modalBox {
    top: 45px;
  }
  .user-profile-popup {
    padding: 10px;
    margin-top: 20px;
    height: 311px;
  }
  .change_password-form {
    margin-top: 10px;
  }
  .nav-tabs .nav-link {
    background-color: var(--white);
    border: 0;
      border-top-color: currentcolor;
      border-right-color: currentcolor;
      border-bottom-color: currentcolor;
      border-left-color: currentcolor;
    border-radius: 30px;
    color: var(--text-color);
    margin: 0;
    text-align: center;
  }
  .nav-tabs .nav-link {
    background-color: var(--white);
    border: 0;
    border-radius: 30px;
    color: var(--text-color);
    margin: 0;
    text-align: center;
    width: 100%;
  }
  .nav-tabs  li {
    width: 50%;
  }
  .add-profile-modal .nav-tabs {
    align-items: center;
    border: 1px solid var(--gray-400);
    border-radius: 20px;
    box-shadow: 0 0 5px var(--gray-400);
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: auto;
  }
  .nav-tabs .nav-link.active {
    background: var(--primary-600);
    background: radial-gradient(circle,var(--primary-600) 23%,var(--primary-600) 100%);
    border-color: var(--primary-color);
    color: var(--white);
  }
 
@media (max-width: 600px) {
    .language {
        width: 50%;
    }
}