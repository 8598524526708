.table.linkbase-table td {
    position: relative;
    padding: 5px 8px;
}

.sensor1 {
    background-color: #ff8427 !important;
    color: black !important;
    width: 111px;
}

.sensor2 {
    background-color: #92d050 !important;
    color: black !important;
    width: 111px;
}

.enabledFields {
    background-color: #F6D0CC;
}
.linkbase-relay {
    width: 162px;
  }