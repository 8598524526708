.errorMessage {
    font-size: 13px;
    width: 100%;
    /* text-align: left; */
}
.threshold-link-box table td, .threshold-link-box .table th {
    padding: 4px 10px;
    width:300px;
  }
  .tableDesign.sensor .table thead th {
    border: 1px solid #acaaaa;
    position: relative;
    text-align: center;
  }