
.image_box {
  text-align: center;
  height: 300px; 
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 auto;
}
.image_box img {
  margin: 0 auto;
  max-width: 300px;
  height: auto;
  max-height: 100%;
  background: black;
  }

.image_text{
    text-align: center;
}
  .view-image-modal .modal-dialog {
    max-width: 800px;
}

.image_box iframe {
  margin: 0 auto;
}
